@import '../styles/variables';
@import '../styles/mixins';

.hero {
    min-height: 100vh;
    width: 100%;
    @include flex-center;
    position: relative;
    overflow: hidden;

    &__gradient {
        position: absolute;
        inset: 0;
        background: radial-gradient(circle at 30% 30%, $color-rose 0%, transparent 70%);
        opacity: 0.2;
    }

    &__content {
        text-align: center;
        z-index: 1;
        padding: 0 $spacing-base;
        animation: fadeIn 0.5s ease-in;
    }

    &__title {
        font-size: 2.25rem;
        font-weight: bold;
        margin-bottom: 1.5rem;

        @include responsive(md) {
            font-size: 3.75rem;
        }

        span {
            color: $color-rose;
        }
    }

    &__description {
        font-size: 1.125rem;
        color: $color-gray-300;
        margin-bottom: 2rem;
        max-width: 36rem;
        margin-left: auto;
        margin-right: auto;

        @include responsive(md) {
        font-size: 1.25rem;
        }

        span{
            color: $color-rose;
        }
    }

    &__button {
        display: inline-block;
        background-color: $color-rose;
        color: $color-white;
        font-weight: 600;
        padding: 0.75rem 2rem;
        border-radius: $border-radius-full;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $color-rose-dark;
        }
    }

    &__scroll-indicator {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
        animation: bounce 2s infinite;
        color: $color-rose;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}