// Colors
$color-rose: #ff1a8c;
$color-rose-light: #ff80bf;
$color-rose-dark: #cc0066;
$color-noir: #1a1a1a;
$color-noir-light: #404040;
$color-noir-dark: #0d0d0d;
$color-white: #ffffff;
$color-gray-300: #d1d5db;
$color-gray-400: #9ca3af;
$color-gray-800: #1f2937;

// Breakpoints
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

// Transitions
$transition-default: all 0.3s ease;

// Z-indices
$z-header: 50;

// Spacing
$spacing-base: 1rem;
$spacing-lg: 2rem;
$spacing-xl: 4rem;

// Border radius
$border-radius-default: 0.5rem;
$border-radius-full: 9999px;