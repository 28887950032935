@import '../styles/variables';
@import '../styles/mixins';

.contact {
    padding: $spacing-xl 0;
    background-color: $color-noir;

    &__container {
        @include container;
    }

    &__title {
        font-size: 1.875rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 3rem;

        span {
        color: $color-rose;
        }
    }

    &__content {
        display: grid;
        gap: 3rem;
        
        @include responsive(md) {
        grid-template-columns: repeat(2, 1fr);
        }
    }

    &__info {
        &-title {
        font-size: 1.5rem;
        font-weight: 600;
        color: $color-white;
        margin-bottom: 1.5rem;
        }

        &-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2rem;
        
        svg {
            color: $color-rose;
        }

        span {
            color: $color-gray-300;
        }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        input, textarea {
        width: 100%;
        padding: 0.75rem 1rem;
        background-color: $color-noir-light;
        border: none;
        border-radius: $border-radius-default;
        color: $color-white;
        transition: box-shadow 0.3s ease;

        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px $color-rose;
        }

        &::placeholder {
            color: $color-gray-400;
        }
        }

        textarea {
        resize: vertical;
        min-height: 8rem;
        }

        button {
        width: 100%;
        background-color: $color-rose;
        color: $color-white;
        font-weight: 600;
        padding: 0.75rem;
        border-radius: $border-radius-default;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $color-rose-dark;
        }
        }
    }
}