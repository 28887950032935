@import 'variables';
@import 'mixins';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: $color-noir;
    color: $color-white;
    font-family: system-ui, -apple-system, sans-serif;
    line-height: 1.5;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    cursor: pointer;
    border: none;
    background: none;
    font: inherit;
}

// Scrollbar styles
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: $color-noir;
}

::-webkit-scrollbar-thumb {
    background: $color-rose;
    border-radius: $border-radius-full;
    
    &:hover {
        background: $color-rose-dark;
    }
}

// Animations
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.animate-fade-in {
    animation: fadeIn 0.5s ease-in;
}

.animate-slide-up {
    animation: slideUp 0.5s ease-out;
}