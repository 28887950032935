@import '../styles/variables';
@import '../styles/mixins';

.about {
    padding: $spacing-xl 0;
    background-color: $color-noir;

    &__container {
        @include container;
        max-width: 64rem;
    }

    &__content {
        display: grid;
        gap: 3rem;
        align-items: center;
        
        @include responsive(md) {
        grid-template-columns: repeat(2, 1fr);
        }
    }

    &__image {
        position: relative;
        border-radius: $border-radius-default;
        overflow: hidden;
        aspect-ratio: 4/5;

        img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        }

        &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: linear-gradient(45deg, rgba($color-rose, 0.2), transparent);
        }
    }

    &__text {
        &-title {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 1.5rem;

        span {
            color: $color-rose;
        }
        }

        &-description {
            color: $color-gray-300;
            margin-bottom: 2rem;
            line-height: 1.8;
        }
    }
}