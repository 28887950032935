@import '../styles/variables';
@import '../styles/mixins';

.skills {
    padding: $spacing-xl 0;
    background-color: $color-noir-light;

    &__container {
        @include container;
    }

    &__title {
        font-size: 1.875rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 3rem;

        span {
        color: $color-rose;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;

        @include responsive(md) {
        grid-template-columns: repeat(2, 1fr);
        }

        @include responsive(lg) {
        grid-template-columns: repeat(3, 1fr);
        }
    }

    &__card {
        background-color: $color-noir;
        padding: 1.5rem;
        border-radius: $border-radius-default;
        @include hover-scale;

        &-title {
        font-size: 1.25rem;
        font-weight: 600;
        color: $color-rose;
        margin-bottom: 1rem;
        }

        &-list {
        list-style: none;
        }

        &-item {
        display: flex;
        align-items: center;
        color: $color-gray-300;
        margin-bottom: 0.5rem;

        &::before {
            content: '';
            display: inline-block;
            width: 0.5rem;
            height: 0.5rem;
            background-color: $color-rose;
            border-radius: 50%;
            margin-right: 0.5rem;
        }
        }
    }
}