@import "../styles/variables";

.navbar_container{
    z-index: 99;
    position: fixed;
    top: 0;
    left: 30%;
    right: 30%;
    background-color: rgba($color-noir, 0.9);
    overflow: hidden;
    border-radius: 0 0 25px 25px;
    
    &_links{
        position: relative;
        padding: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        list-style-type: none;
        box-sizing: border-box;
        
        
        &_link{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            text-align: center;
            color: $color-rose;
            font-size: 40px;
            text-decoration: none;
            text-transform: uppercase;
            transition: all 0.4s ease-out;

            &::after{
                transform-origin: 0 100%;
                transition: all 0.4s ease-out;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: rgba($color-rose, 0.9);
            }

            &:hover{
                color: $color-rose;
                transform: translateY(-50px);
            }
            &:hover::after{
                position: absolute;
                top: 0;
                bottom: 100%; // Positionnez-le à droite de l'élément d'origine
                padding: 50px 0; // Ajoutez du padding pour l'espace
                color: $color-white;
                font-size: 14px;
                transform: perspective(400px);
                content: attr(data-content);
            }
        }
    }
}


@media (max-width: 1024px){
    .navbar_container{
        left: 20%;
        right: 20%;
    }
}