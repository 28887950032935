.error_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;

    &_logo{
        font-size: 120px;
        color: magenta;
    }

    &_link{
        font-size: 18px;

        & a{
            text-decoration: none;
            color: magenta;
        }
    }
}