@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @mixin container {
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 $spacing-base;
  }
  
  @mixin hover-scale {
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
  
  @mixin gradient-overlay {
    background: linear-gradient(to top, $color-noir, transparent);
  }
  
  @mixin responsive($breakpoint) {
    @if $breakpoint == sm {
      @media (min-width: $breakpoint-sm) { @content; }
    } @else if $breakpoint == md {
      @media (min-width: $breakpoint-md) { @content; }
    } @else if $breakpoint == lg {
      @media (min-width: $breakpoint-lg) { @content; }
    } @else if $breakpoint == xl {
      @media (min-width: $breakpoint-xl) { @content; }
    }
  }