@import '../styles/variables';
@import '../styles/mixins';

.projects {
    padding: $spacing-xl 0;
    background-color: $color-noir;

    &__container {
        @include container;
    }

    &__title {
        font-size: 1.875rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 3rem;

        span {
        color: $color-rose;
        }
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 3rem;
    }

    &__filter {
        padding: 0.5rem 1.5rem;
        border-radius: $border-radius-full;
        transition: all 0.3s ease;

        &--active {
        background-color: $color-rose;
        color: $color-white;
        }

        &:not(&--active) {
        background-color: $color-noir-light;
        color: $color-gray-400;

        &:hover {
            color: $color-white;
        }
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;

        @include responsive(md) {
        grid-template-columns: repeat(2, 1fr);
        }

        @include responsive(lg) {
        grid-template-columns: repeat(3, 1fr);
        }
    }

    &__card {
        background-color: $color-noir-light;
        border-radius: $border-radius-default;
        overflow: hidden;
        @include hover-scale;

        &-image {
        position: relative;
        height: 12rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            @include gradient-overlay;
        }
        }

        &-content {
        padding: 1.5rem;
        }

        &-title {
        font-size: 1.25rem;
        font-weight: 600;
        color: $color-white;
        margin-bottom: 0.5rem;
        }

        &-description {
        color: $color-gray-400;
        margin-bottom: 1rem;
        }

        &-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-bottom: 1rem;
        }

        &-tag {
        background-color: rgba($color-rose, 0.1);
        color: $color-rose;
        font-size: 0.875rem;
        padding: 0.25rem 0.75rem;
        border-radius: $border-radius-full;
        }

        &-links {
        display: flex;
        gap: 1rem;

        a {
            color: $color-white;
            transition: color 0.3s ease;

            &:hover {
            color: $color-rose;
            }
        }
        }
    }
}