@import '../styles/variables';
@import '../styles/mixins';

.footer {
    background-color: $color-noir-dark;
    color: $color-gray-400;
    display: flex;

    &__container {
        @include container;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    &__section {
        &-text {
            margin-bottom: 1rem;
        }
    }
}